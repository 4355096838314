<template>
  <section id="404-section">
    <v-responsive
      class="d-flex "
      height="100%"
      width="100%"
      :min-height="minHeight" 
      style="padding-top: 20px;"
    >
      <v-container class="fill-height pa-6 main align-start" >
        <v-responsive
          class="d-flex fill-height pa-4 white align-center" 
          height="100%"
          width="100%"
          :elevation="2"
        >
        <div class="text-center">
          <h1 class="display-2 primary--text">Oops! No page Found! 404</h1>
          <v-alert border="right" colored-border type="error" elevation="2">
            Looks like this page doesn't exist
          </v-alert>
          <v-btn large color="primary" :to="{ name: 'Home' }">Home</v-btn>
        </div>

        </v-responsive>
      </v-container>
    </v-responsive>
  </section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    metaInfo: { title: 'Oops! No Page Found' },

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },

    provide: {
      heading: { align: 'center' },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px - 80px)`
      },
    },
  }
</script>
