var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "404-section" } },
    [
      _c(
        "v-responsive",
        {
          staticClass: "d-flex ",
          staticStyle: { "padding-top": "20px" },
          attrs: { height: "100%", width: "100%", "min-height": _vm.minHeight },
        },
        [
          _c(
            "v-container",
            { staticClass: "fill-height pa-6 main align-start" },
            [
              _c(
                "v-responsive",
                {
                  staticClass: "d-flex fill-height pa-4 white align-center",
                  attrs: { height: "100%", width: "100%", elevation: 2 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("h1", { staticClass: "display-2 primary--text" }, [
                        _vm._v("Oops! No page Found! 404"),
                      ]),
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            border: "right",
                            "colored-border": "",
                            type: "error",
                            elevation: "2",
                          },
                        },
                        [_vm._v(" Looks like this page doesn't exist ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            color: "primary",
                            to: { name: "Home" },
                          },
                        },
                        [_vm._v("Home")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }